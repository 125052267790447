import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ArticlesComponent from "../components/articles"
import CategoryTag from "../components/categoryTag"
import Seo from "../components/seo"
import NewsletterForm from "../components/newsletterForm"
import styled from "styled-components"

import Layout from "../components/layout"

const Home = () => {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query {
            allStrapiArticle {
              edges {
                node {
                  strapiId
                  title
                  description
                  url
                  createdAt
                  users {
                    id
                  }
                  category {
                    name
                  }
                  media
                  approved
                }
              }
            }

            allStrapiCategory {
              edges {
                node {
                  strapiId
                  name
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Seo
              title={
                "The home of PLeadership, Personal and Professional growth"
              }
            />
            <section className="hero is-dark is-small">
              <div className="hero-body">
                <div className="container pb-6">
                  <div className="columns is-centered is-top">
                    <div className="column is-7 mt-6 has-text-centered">
                      <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                        The home of Leadership, Personal and Professional growth
                      </h1>
                      <h2 className="is-size-6 has-text-white mt-4">
                        A constantly growing and curated list of Leadership,
                        Personal and Professional growth resources.
                      </h2>
                    </div>
                  </div>
                  <div
                    className="field is-grouped is-grouped-multiline is-top"
                    style={{ justifyContent: "center" }}
                  >
                    {data.allStrapiCategory.edges.map((category, i) => {
                      return <CategoryTag type={category.node.name} key={i} />
                    })}
                  </div>

                  <StyledDetail className="detail-1">
                    <svg
                      width="287"
                      height="264"
                      viewBox="0 0 287 264"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M165.81 215.775C162.456 220.592 124.452 241.401 101.698 253.586C92.2463 258.647 80.5389 255.235 75.1779 245.95L20.1492 150.637C15.3846 142.385 17.1041 131.917 24.2582 125.622L96.7671 61.8234C100.891 58.1949 106.326 56.4222 111.796 56.9214L230.651 67.7672C245.83 69.1523 253.964 86.2948 245.392 98.8975C217.691 139.626 169.233 210.858 165.81 215.775Z"
                        fill="#E96463"
                      />
                    </svg>
                  </StyledDetail>

                  <StyledDetail className="detail-2">
                    <svg
                      width="267"
                      height="343"
                      viewBox="0 0 267 343"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M248.039 190.143C252.512 197.177 259.079 261.91 262.171 294.855C263.173 305.529 255.508 315.004 244.873 316.367L87.5143 336.532C78.0624 337.743 69.0686 332.117 66.0219 323.088L22.1746 193.145C20.4183 187.94 20.8801 182.242 23.4514 177.388L104.606 24.1872C111.741 10.7189 130.693 9.82777 139.013 22.5982C173.268 75.18 243.491 182.993 248.039 190.143Z"
                        fill="#6ED7D3"
                      />
                    </svg>
                  </StyledDetail>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="container">
                <div className="columns">
                  <div className="column is-8">
                    <p className="has-opacity-65">BEST OF THE WEEK</p>
                    <ArticlesComponent
                      articles={data.allStrapiArticle.edges}
                      defaultSort="best"
                      limit="2"
                    />
                  </div>
                </div>
              </div>
            </section>

            <div className="section">
              <div className="container">
                <div className="columns">
                  <div className="column is-12">
                    <NewsletterForm />
                  </div>
                </div>
              </div>
            </div>

            <section className="section">
              <div className="container">
                <div className="columns">
                  <div className="column is-8">
                    <ArticlesComponent
                      articles={data.allStrapiArticle.edges}
                      defaultSort="newest"
                      showSort={true}
                    />
                  </div>
                  <div className="column is-4">
                    <div className="content">
                      <p>
                        <span className="has-opacity-65">GO TO</span>
                      </p>
                      <div className="field is-grouped is-grouped-multiline">
                        {data.allStrapiCategory.edges.map((category, i) => {
                          return (
                            <CategoryTag type={category.node.name} key={i} />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      />
    </Layout>
  )
}

export default Home

const StyledDetail = styled.div`
  position: absolute;
  bottom: -80px;
  &.detail-1 {
    left: 0;
  }
  &.detail-2 {
    right: 0;
  }
`
